
  function bannerAd728x90(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("935636682", "728x90", "935636682");
      });
    }
    catch (error) {}
  }

  function fullWidthMiddle970x90(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("438126864", "970x90", "438126864");
      });
    }
    catch (error) {}
  }

  function middle728x90(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("654066635", "728x90", "654066635");
      });
     }
     catch (error) {}
  }


  function sidePageAdvert300x600(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("370465602", "300x600", "370465602");
      });
    }
    catch (error) {}
  }

  function storyPageSidePageAdvert300x600(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("674508636", "300x600", "674508636");
      });
  }
  catch (error) {}
  }

  function storyPageMiddlePageAdvert728x90(){
    try {
      window._mNHandle.queue.push(function (){
          window._mNDetails.loadTag("526582645", "728x90", "526582645");
      });
  }
  catch (error) {}
  }


  function bannerAdTest(x){
    console.log('test'+x);
  }
